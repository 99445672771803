<template>
  <section class="d-block filters d-sm-flex">
    <v-sheet max-width="250px" class="mr-4" v-if="role !== 'caretaker'">
      <v-select outlined
                label="Caretaker"
                v-model="filters.caretaker_id"
                item-value="id"
                item-text="full_name"
                @change="filter()"
                :items="caretakers">
      </v-select>
    </v-sheet>
    <v-sheet max-width="250px" class="mr-4" v-if="$route.name !== 'customers.messages'">
      <select-customer-dropdown @input="filter()" v-model="filters.customer_id" />
    </v-sheet>
    <v-sheet max-width="250px">
      <v-select outlined
                label="Quarter"
                v-model="filters.quarter"
                @change="filter()"
                :items="quarters">
      </v-select>
    </v-sheet>
    <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
  </section>
</template>

<script>
import users from "../../../../../api/modules/users";
import SelectCustomerDropdown from "../../../../../components/forms/SelectCustomerDropdown";
import {EventBus} from "../../../../../bootstrap/EventBus";
import {mapGetters} from "vuex";

export default {
  name: "MessagesTableFilters",
  components: {
    SelectCustomerDropdown
  },
  data: () => ({
    caretakers: [],
    quarters: [
      {
        text: 'First',
        value: 1
      },
      {
        text: 'Second',
        value: 2
      },
      {
        text: 'Third',
        value: 3
      },
      {
        text: 'Forth',
        value: 4
      },
    ],
    filters: {
      caretaker_id: null,
      customer_id: null,
      quarter: null
    },
  }),
  computed: {
    ...mapGetters('auth', ['role']),
  },
  mounted: function () {
    users.fetchAssigneesDictionary().then(res => {
      this.caretakers = res.data;
    })
    if (this.$route.name === 'users.caretaker-messages') {
      this.filters.caretaker_id = this.$route.params.id
    }
    if (this.$route.name === 'customers.messages') {
      this.filters.customer_id = this.$route.params.id
    }
    this.filter()
  }, 
  methods: {
    /** Emit Filters event on Filters Change **/
    filter: function () {
      EventBus.$emit('messages-filters', this.filters);
    },
    resetFilters: function () {
      /** On Project Selection we reset filters to default **/
      this.filters = {
        caretaker_id: this.$route.name === 'users.caretaker-messages' ? this.$route.params.id : null,
        customer_id: this.$route.name === 'customers.messages' ? this.$route.params.id : null,
        quarter: null
      };

      this.filter();
    },
  },
}
</script>